import React, { createContext } from "react"

const initialState = {
  lang: 'kr',
  toggleLang: () => { },
}

const LangContext = createContext(initialState);


class LangProvider extends React.Component {
  state = {
    lang: 'kr'
  }

  toggleLang = () => {
    this.setState((state) => {
      return { lang: state.lang === 'kr' ? 'en' : 'kr' }
    })
  }

  render() {
    const { children } = this.props
    const { lang } = this.state

    return (
      <LangContext.Provider value={{ lang, toggleLang: this.toggleLang }}>
        {children}
      </LangContext.Provider>
    );
  }
}

export default LangContext;

export { LangProvider }